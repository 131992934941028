@import url('https://fonts.googleapis.com/css2?family=Quicksand:wght@300;400;500;600;700&display=swap');

.change-font {
  font-family: 'Quicksand', sans-serif;
}

input {
  font-family: 'Quicksand', sans-serif;
}

::placeholder {
  font-family: 'Quicksand', sans-serif;
  color: rgb(116, 116, 116);
  opacity: 1;
}

.btn {
  font-family: 'Quicksand', sans-serif;
  font-size: 18px;
}

.background-image {
  background-position: center;
  background-size: cover;
  display: block;
  position: fixed;
  z-index: -1;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.main-logo {
  width: 90px;
}

.regular-spacer {
  height: 10px;
}

.double-spacer {
  height: 20px;
}

.medium-spacer {
  height: 50px;
}

.big-spacer {
  height: 100px;
}

.form-box {
  margin-top: 30px;
}

.special-input {
  padding-left: 40px !important;
  padding-right: 40px !important;
}

.icon-color-1 {
  color:#1e88e5;
}

.icon-color-2 {
  color:#8e24aa;
}

.special-card {
  background-color:rgba(255,255,255,0.9);
  border-radius: 20px;
}

.one-product-box {
  padding-left: 10%;
  padding-right: 10%; 
  height: 600px; 
  border: 10px solid transparent;
}

@media all and (max-width: 1000px) {
  .one-product-box {
    padding-left: 2%;
    padding-right: 2%; 
  }
}

.one-product-card {
  height: 500px; 
  background-color:white; 
  padding: 10px; 
  border-radius: 20px; 
  border: 4px solid #dcdcdc;
}

.mini-menu {
  position: fixed; 
  top: 10px; 
  right: 10px;
}

.modal-content-size {
  height: 200px;
}

.modal-main-link {
  font-size: 28px;
  margin-top: -2px;
  margin-bottom: -2px;
}

.modal-link {
  font-size: 20px;
}

@media all and (max-width: 992px) {
  .modal-main-link {
    font-size: 22px;
  }
  
  .modal-link {
    font-size: 18px;
  }
}

@media all and (max-width: 400px) {
  .modal-main-link {
    font-size: 18px;
  }
  
  .modal-link {
    font-size: 16px;
  }
}

#phones_list {
  text-align: center; 
  padding-left: 10%;
  padding-right: 10%;
}

@media all and (max-width: 992px) {
  #phones_list {
    padding-left: 2%;
    padding-right: 2%;
  }
}

.flex-main-box {
  height: 520px; 
  border:10px solid transparent;
}

.flex-product-box {
  height: 480px; 
  background-color:white; 
  padding: 10px; 
  border-radius: 20px; 
  border: 4px solid #dcdcdc;
}

@media all and (max-width: 400px) {
  .flex-main-box {
    height: 560px; 
  }
  
  .flex-product-box {
    height: 520px; 
  }
}

.flex-image {
  max-width: 120px; 
  margin-top: 10px;
}

.flex-link {
  color:cornflowerblue;
}

.flex-title {
  font-size: 28px;
  text-transform: uppercase;
}

.bilemo-products-title {
  margin-bottom: 30px;
}

.flex-summary {
  padding: 10px;
}

.flex-price {
  font-weight: bold;
  font-size: 24px;
}

@media all and (max-width: 1200px) {
  .flex-price {
    margin-top: -10px;
  }
}

@media all and (max-width: 350px) {
  .flex-title {
    font-size: 20px;
  }

  .flex-summary {
    padding: 1px;
  }

  .flex-price {
    margin-top: -2px;
    font-size: 16px;
  }
}

